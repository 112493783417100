<template>
    <div class="headertoab">
        <div class="dei_rowp">
            <el-row v-if="btnState2"><el-button size="small" type="primary" class="but" @click="handRegister">
            <i class="el-icon-plus"></i>
            {{titlename.name}}
            </el-button></el-row>
            <div @click="handFromshow"><el-input
            class="dev_dowm"
            :placeholder="$t('devtable.qdjcx')"
            suffix-icon="el-icon-arrow-down"
            :readonly="true"
            size="small"
            >
            </el-input></div>
			<el-row v-if="btnState"><el-button size="small" type="primary" class="but" @click="handBatch">
			{{titlename.pltb}}
			</el-button></el-row>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        titlename:Object,
        flags:Boolean,
		btnState:{
			type:Boolean,
			default:false
		},
		btnState2:{
			type:Boolean,
			default:true
		}
    },
    data(){
        return{
           

            }
        },
    methods:{
        handRegister(){
            this.$emit('handRegister')
        },
		handBatch(){
			this.$emit('handBatch')
		},
        handFromshow(){
            this.$emit('handFromshow')
        },
        Excelexport(){
            this.$emit('Excelexport')
        }
    }
}
</script>
<style lang="less" scoped>
.headertoab{
    display: flex;
    justify-content: space-between;
}
.dei_rowp{
    display: flex;
    width: 30%;
    .dev_dowm{
    width: 200px;
    }
    .but{
    margin-left: 20px;
    margin-right: 30px;
    }
}
</style>