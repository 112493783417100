<template>
    <div class="datasoures">

        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
    
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <!-- <el-form-item label="部门">
                    <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item> -->
                <el-form-item :label="$t('devtable.azwz')">
                    <el-input v-model="formInline.name" :placeholder="$t('devtable.azwz')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.sbxlhD')">
                    <el-input v-model="formInline.number" :placeholder="$t('devtable.sbxlhD')"></el-input>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables 
            @handlerow="handlerow"
            @handlpages="handlpages" 
            :tableLabel="tableLabel" 
            @handleSizeChange="handleSizeChange"
            :tableData="tableData" 
            :flags="flags" 
            :configs="configs" 
            :operation="operation">
        </Tables>
        <div>
            <el-dialog
                :title="$t('devtable.tjsbkqH')"
                :visible.sync="centerDialogVisible"
                append-to-body
                width="35%"
                center>
                <div class="shear" style="display: flex;">
                    <span class="name">{{$t('devtable.azwz')}}:</span>
                    <el-row style="flex:4">
                        <el-input size="small" v-model="name" :placeholder="$t('devtable.azwz')"></el-input>
                    </el-row>
                    <el-button type="primary" size="small" class="tbut" @click="Quesrt">{{$t('devtable.cx')}}</el-button>
                </div>
                <div class="shear" style="display: flex;margin-top:20px">
                    <span class="name">{{$t('devtable.sbxlh')}}:</span>
                    <el-row style="flex:4">
                        <el-input v-model="device_id" size="small" :placeholder="$t('devtable.sbxlh')"></el-input>
                    </el-row>
                    <el-button type="primary" size="small" class="tbut" @click="SeverQuest">{{$t('devtable.cx')}}</el-button>
                </div>
                <div style="margin-top:20px">
                    <el-table
                        :data="tableDataf"
                        border
                        @selection-change="handleSelectionChange"
                        style="width: 100%">
                        
                        <el-table-column
                            type="selection"
                            align="center"
                             width="40"
                           >
                        </el-table-column>

                        <el-table-column
                        prop="name"
                        :label="$t('devtable.azwz')"
                        align="center"
                       >
                        </el-table-column>

                        <el-table-column
                        prop="serial_number"
                        :label="$t('devtable.sbxlh')"
                        align="center"
                        >

                        </el-table-column>
                        <!-- <el-table-column
                        prop="address"
                        label="地址">
                        </el-table-column> -->
                    </el-table>
                   
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="Determine">{{$t('tablename.qd')}}</el-button>
                </span>
            </el-dialog>
        </div>
        <Clearl ref="clearl" @clearhand="clearhand" :data="clearldata"></Clearl>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
// import Select from '@/components/selectree/selectchoice'
import Clearl from './components/cleadr'
export default {
    components:{
        Tables,
        Tabheader,
        // Select,
        Clearl
    },
    data(){
        return{
            iShow:false,
            formInline: {
                number: '',
                name: ''
            },
            device_id:'',
            name:'',
            centerDialogVisible: false,
            
            
            tableData: [],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            
            tableDataf: [],
            tabselectId:[],
            device_idlist:[],
            clearldata:{},
            UserInfo:''
        }
    },
    computed:{
        titlename:function(){
            return{
                name:this.$t('devtable.tjsbkq'),
                placeholdername:this.$t('devtable.qdjcx')
            }
        },
        operation: function(){
            return{
                width:'200px',
                data:[
                  {
                    name: this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            }
        },
        tableLabel:function(){
            return [
                {
                    prop: 'device_name',
                    label: this.$t('devtable.azwz'),
                    align: 'center'
                },
                {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbxlh'),
                    align: 'center'
                },
                {
                    prop: 'door_direction',
                    label: this.$t('devtable.kmzt'),
                    align: 'center'
                },
                {
                    prop: 'open_mode',
                    label: this.$t('devtable.kmmsI'),
                    align: 'center'
                },
                
            ]
        }
    },
    watch:{
        'device_id':function(oval,nval){
            if(oval!=nval){
                this.Equipmentlist()
            }
        },
        'name':function(oval,nval){
            if(oval!=nval){
                this.Equipmentlist()
            }
        },
        'formInline.name':function(oval,nval){
            if(oval!=nval){
                this.getinfoList()
            }
        },
        'formInline.number':function(oval,nval){
            if(oval!=nval){
                this.getinfoList()
            }
        },
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getinfoList()
        this.Equipmentlist()
    },
    methods:{
        // 获取数据来源列表
        getinfoList(v,name,number){
            let data={
                method:'att.devices.get',
                agent_id:this.UserInfo.agent_id,
                name:name?name:'',
                number:number?number:"",
                page_index:v?v:this.configs.page_index,
                page_size:this.configs.pagesize
            }
            this.$serve(data).then(res=>{
                this.tableData = res.data.data.records
                this.configs.total=res.data.data.recordCount
                this.tableData.forEach(el=>{
                    if(el.door_direction==0){
                        el.door_direction={
                            state:true,
                            name:this.$t('devtable.j'),
                            type:'primary'
                        }
                    }else{
                        el.door_direction={
                            state:true,
                            name:this.$t('devtable.c'),
                            type:'warning'
                        }
                    }
                    if(el.open_mode==0){
                        el.open_mode={
                            state:true,
                            name:this.$t('devtable.ckms'),
                            type:'success'
                        }
                    }else{
                        el.open_mode={
                            state:true,
                            name:this.$t('devtable.ckms'),
                            type:'danger'
                        }
                    }
                })
            })
        },
        // 添加
        Determine(){
            let data={
                method:"att.device.add",
                agent_id:this.UserInfo.agent_id,
                device_id:this.device_idlist.toString()
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.$message({
                        message: this.$t('devtable.tjcg'),
                        type: 'success'
                    });
                    this.getinfoList()
                }else{
                    this.$message({
                        message: this.$t('devtable.tjsb'),
                        type: 'error'
                    });
                }
            })
            this.centerDialogVisible = false
        },
        //  设备列表ques
        Equipmentlist(name,serial_number){
            let data={
                method:'co.device.list',
                page_index:1,
                page_size:10,
                name:name?name:"",
                serial_number:serial_number?serial_number:"",
                device_state:"-1",
                lock_mode:'-1',
                agent_id:this.UserInfo.agent_id,
            }
            this.$serve(data).then(res=>{
                this.tableDataf = res.data.data    
            })
        },
        // 查询
        Quesrt(){
            this.Equipmentlist(this.name)
        },
        SeverQuest(){
             this.Equipmentlist("",this.device_id)
        },
        // 表格选中
        handleSelectionChange(v){
            this.device_idlist=[]
            this.tabselectId = v
            this.tabselectId.forEach(el=>{
                // this.name= el.name
                this.device_idlist.push(el.id) 
            })
            // this.device_id =this.device_idlist.toString()
        },
        // 分页
        handlpages(v){
            this.configs.page_index=v
            this.getinfoList()
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getinfoList()
        },
        // 子组件传过来的部门id
        handleCheckChange(val){
            // console.log(val)
        },
        // 修改删除
        handlerow(v,name){
            console.log(v)
            if(name=="删除" || name=="Delete"){
                this.clearldata = {
                    device_id:v.device_id,
                    agent_id:this.UserInfo.agent_id,
                    method:"att.device.delete"
                }
                this.$refs.clearl.dialogVisible = true
            }
        },
        // 删除成功后的回调
        clearhand(){
            this.getinfoList()
        },
        handRegister(){
            this.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        // 列表页面的查询
        onSubmit() {
            this.getinfoList('',this.formInline.name,this.formInline.number)
        }
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
    .shear{
        display: flex;
    }
}
.name{
    flex: 2;
    line-height: 30px;
}
.tbut{
    margin-left: 20px;
}
</style>